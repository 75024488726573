
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import OutputSaction from '@/components/sections/OutputSaction.vue'

export default defineComponent({
  name: 'ReOutput',
  components: {
    Main,
    OutputSaction
  }
})
