
import { defineComponent } from 'vue'
import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'Output',
  data () {
    return {
      amount: 0,
      selectAmount: null,
      statistics: {},
      translateData: {}
    }
  },
  components: {
    RefLink
  },
  mounted () {
    this.translate()
    this.getDashboardStatistics()
  },
  methods: {
    translate (data) {
      data = []
      data.push('menu_withdrawal', 'for_withdrawal', 'withdraw', 'commision')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToPay () {
      if (this.selectAmount !== null && this.selectAmount > 0) {
        this.$router.push(`/dashboard/select-wallet?${this.selectAmount}`)
      } else console.log('hello')
    },
    addMaxAmount () {
      this.selectAmount = this.amount
    },
    getDashboardStatistics () {
      this.$store.dispatch('getPayments/getStatistics').then(
        (response) => {
          this.statistics = response
          this.amount = this.statistics.available_for_conclusion
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
